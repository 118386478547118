import React from 'react';
import Spacer from '../components/atoms/spacer';
import Button from '../components/atoms/button';
import Typography from '../components/atoms/typography';
import TestimonialCard from '../components/molecules/testimonialCard';
import { infoCards, testimonialCards } from '../services/pageData';
import { Row, Col, Visible } from 'react-grid-system';
import { Link, navigate } from 'gatsby';
import InfoCard from '../components/molecules/infoCard';
import ContactForm from '../components/organisms/contactForm';
import HeroBanner from '../components/organisms/heroBanner';
import styled, { css } from 'styled-components';
import family from '../images/family.png';
import panbox from '../images/panbox.png';
import panboxHeroImage from '../images/panbox-hero.png';
import theme from '../styles/theme';
import Icon from '../components/atoms/icon';
import ArrowIconImageRed from '../images/icons/arrow-right-red.png';
import girlAndMan from '../images/girl-man.png';
import backgroundCircle from '../images/circle-background.png';
import PriceTag from '../components/molecules/priceTag';
import Container from '../components/atoms/container';
import useScreenClassHelper from '../hooks/useScreenClassHelper';
import SEO from '../components/seo';
import Routes from '../services/routes';

const StyledButton = styled(Button)`
  height: 50px;
`;

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: row;
  text-decoration: none;
`;

const StyledSubHeader = styled(Typography)`
  font-weight: 300;
`;

const StyledLinkText = styled(Typography)`
  width: auto;
  font-weight: bold;
  margin-right: 0.5rem;
  color: ${(props) => props.theme.colors.secondary};
`;

const Image = styled.img`
  width: 100%;
  margin: auto;
`;

const ContactFormWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.primary};
`;

const PageWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.formBackground};
`;

const PriceTagWrapper = styled.div`
  position: absolute;
  ${(props: { isMobile: boolean }) =>
    props.isMobile
      ? null
      : css`
          right: 40px; ;
        `}
`;

const TestimonialWithBackground = styled.div`
  background-image: ${() => `url(${backgroundCircle})`};
  background-repeat: no-repeat;
  background-position: top;
  background-size: ${(props: { isMobile: boolean }) =>
    props.isMobile ? '150% 125%' : '125% 150%'};
`;

const ContainerRightHero = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const Header = styled(Typography)`
  font-size: 2rem;
`;

const GO_TO_PRODUCT = '/produkter';

type Props = {
  title?: string;
  description?: string;
  descriptionPanBox?: string;
  ctaButtonTitle?: string;
};

const HomeTemplate: React.FC<Props> = ({
  title = 'Med vår hjälp kan du känna dig trygg vid nästa virusutbrott.',
  description = 'Trygghet i en box',
  descriptionPanBox = 'panBox är lösningen som hjälper dig att skydda dig och din familj mot virusutbrott i samhället. Som kund hos oss står du inte utan skyddsprodukter vid nästa virusutbrott och kan känna dig trygg. Med vår hjälp har du tillgång till ett eget beredskapslager. Vi säkerställer att all utrustning finns tillgänglig och du kan enkelt beställa hem ditt skyddskit så du är förberedd inför nästa smitta.',
  ctaButtonTitle = 'Boka din panBox nu'
}) => {
  const { IS_MOBILE } = useScreenClassHelper();
  const contactFormTextAlignment = IS_MOBILE ? 'left' : 'center';

  const handleClick = (path: string) => {
    navigate(path);
  };

  return (
    <>
      <SEO
        title="Skyddskit och produkter vid virusutbrott | panBox"
        description="Skydda dig och dina nära och kära vid nästa virusutbrott. Färdiga paket med allt du behöver för att minska risken att bli smittad vid nästa virusutbrott."
      />
      <PageWrapper theme={theme}>
        <HeroBanner
          leftPane={
            <>
              <Visible sm xs>
                <Spacer spacing={4} />
              </Visible>
              <Container>
                <Row>
                  <Col md={12}>
                    <Spacer spacing={2} />
                    <Header color="textSecondary" variant="header">
                      {title}
                    </Header>
                  </Col>
                  <Col md={12}>
                    <Spacer />
                    <StyledSubHeader color="textSecondary" variant="h3">
                      {description}
                    </StyledSubHeader>
                  </Col>
                  <Col md={10} sm={6}>
                    <Spacer />
                    <StyledButton onClick={() => handleClick(Routes.ORDER)}>
                      {`${ctaButtonTitle}!`}
                    </StyledButton>
                  </Col>
                </Row>
              </Container>
            </>
          }
          rightPane={
            <ContainerRightHero>
              <Spacer spacing={4} />
              <Image alt="panBox innehåll" src={panboxHeroImage} />
            </ContainerRightHero>
          }
        />

        <Spacer spacing={4} />
        <Container>
          <Row justify="center" align="center">
            <Col md={6}>
              <PriceTagWrapper isMobile={IS_MOBILE}>
                <PriceTag />
              </PriceTagWrapper>
              <Image src={panbox} />
            </Col>
            <Col md={6}>
              <Typography color="primary" variant="h2">
                Vad är panBox?
              </Typography>
              <Spacer />
              <Typography>{descriptionPanBox}</Typography>
              <Spacer spacing={0.5} />
              <Typography>
                En trygghet för dig och din familj helt enkelt.
              </Typography>
              <Spacer spacing={2} />
              <StyledLink to={GO_TO_PRODUCT}>
                <StyledLinkText theme={theme} color="primary">
                  Kolla innehållet i din panBox!
                </StyledLinkText>
                <Icon
                  width={25}
                  src={ArrowIconImageRed}
                  onClick={() => navigate(GO_TO_PRODUCT)}
                  alt="pil"
                />
              </StyledLink>
            </Col>
          </Row>
          <Row>
            {infoCards.map((item) => {
              return (
                <Col key={item.title} md={4} xs={12}>
                  <InfoCard height="600px" {...item} />
                </Col>
              );
            })}
          </Row>
          <Spacer spacing={IS_MOBILE ? 2 : 4} />
          <Typography align="center" variant="h3" color="primary">
            Så här fungerar panBox
          </Typography>
          <Spacer />
          <Row justify="center">
            <iframe
              title="vimeo-player"
              src="https://player.vimeo.com/video/600095675?h=710e6a38d7"
              width="100%"
              height={IS_MOBILE ? '200' : '500'}
              frameBorder="0"
              allowFullScreen
            />
          </Row>
          <Spacer spacing={IS_MOBILE ? 2 : 4} />
        </Container>
        <Spacer spacing={3} />
        <HeroBanner
          backgroundColor="secondary"
          leftPane={
            <Row>
              <Col md={12}>
                <Spacer spacing={3} />
                <Typography color="textSecondary" variant="header">
                  Säkra din panBox idag!
                </Typography>
              </Col>
              <Col md={4}>
                <Spacer />
                <Button primary onClick={() => handleClick('/bestall')}>
                  {ctaButtonTitle}
                </Button>
              </Col>
            </Row>
          }
          rightPane={
            <ContainerRightHero>
              <Spacer spacing={4} />
              <Image alt="panBox girl" src={girlAndMan} />
            </ContainerRightHero>
          }
        />
        <Container>
          <Row justify="center" align="center">
            <Col md={6}>
              <Image src={family} />
            </Col>
            <Col md={6}>
              <>
                <Typography color="primary" variant="header">
                  Familjen i centrum
                </Typography>
                <Spacer />
                <Typography>
                  Vi har skapat panBox för att skapa trygghet till dig och din
                  familj. Genom att boka en panBox garanterar du att ni har
                  tillgång till nödvändiga skyddsprodukter när ni behöver dem.
                </Typography>
                <Spacer />
                <StyledLink to={GO_TO_PRODUCT}>
                  <StyledLinkText color="primary">
                    Kolla innehållet i din panBox!
                  </StyledLinkText>
                  <Icon
                    width={25}
                    src={ArrowIconImageRed}
                    onClick={() => navigate(GO_TO_PRODUCT)}
                    alt="pil röd"
                  />
                </StyledLink>
              </>
            </Col>
          </Row>
        </Container>
        <Spacer spacing={4} />
        <TestimonialWithBackground isMobile={IS_MOBILE}>
          <Spacer spacing={2} />
          <Container>
            <Row>
              {testimonialCards.map((item) => {
                return (
                  <Col key={item.testimonial} md={4} xs={12}>
                    <TestimonialCard
                      testimonial={item.testimonial}
                      by={item.by}
                    />
                  </Col>
                );
              })}
            </Row>
          </Container>
          <Spacer spacing={2} />
        </TestimonialWithBackground>

        <ContactFormWrapper theme={theme}>
          <Container>
            <Row>
              <Col md={10} offset={{ md: 1 }}>
                <ContactForm
                  header={
                    <>
                      <Typography
                        align={contactFormTextAlignment}
                        color="textSecondary"
                        variant="h2"
                      >
                        Har du frågor om panBox?
                      </Typography>
                      <Spacer />
                      <StyledSubHeader
                        align={contactFormTextAlignment}
                        color="textSecondary"
                        variant="h3"
                      >
                        Skicka ett meddelande till oss så återkommer vi inom
                        kort!
                      </StyledSubHeader>
                      <Spacer spacing={2} />
                    </>
                  }
                  termsColor="textSecondary"
                  ctaTitle="Skicka"
                  backgroundColor="primary"
                />
              </Col>
            </Row>
          </Container>
          <Spacer spacing={3} />
        </ContactFormWrapper>
      </PageWrapper>
    </>
  );
};

export default HomeTemplate;
