import React from 'react';
import HomeTemplate from '../templates/homeTemplate';

const Home2Page: React.FC = () => {
  return (
    <HomeTemplate
      title="Känn dig trygg inför nästa virusutbrott"
      description="Ditt privata beredskapslager"
      descriptionPanBox="panBox är ett beredskapskit med skyddsprodukter som hjälper dig och din familj mot
        smittspridning. Som kund hos oss står du inte utan skyddsprodukter vid nästa virusutbrott.
        Vi lagerhåller och säkerställer att all utrustning finns tillgänglig på ditt privata
        beredskapslager. Du kan enkelt med ett knapptryck beställa hem din panbox vid behov."
      ctaButtonTitle="Beställ panBox nu"
    />
  );
};

export default Home2Page;
